<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">医院列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
      @submit="handleSearch" @submit.native.prevent>
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit">
            查询
          </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="所属医院">
          <a-input v-model="query.hospital_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系人">
          <a-input v-model="query.contact"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input v-model="query.contact_phone"></a-input>
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="add" style="margin-left: 10px">+新增</a-button>
      <a-form-model-item style="float: right; margin-right: 0">
        <a-button type="primary" @click="importHos" style="margin-right: 20px">批量导入</a-button>
      </a-form-model-item>
    </a-row>
    <a-table class="main-table" :pagination="{
      current: query.page,
      total: pagetotal,
      showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
    }" @change="listChange" :columns="columns" :data-source="data" rowKey="hospital_id" style="margin-top: 14px"
      v-viewer>
      <span slot="rent_count" slot-scope="text, record">
        <a @click="toOrder(record.customer_id)">{{ record.rent_count }}次</a>
      </span>
      <span slot="area" slot-scope="text, record">
        {{ record.pro + record.city + record.area + record.address }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record)">编辑</a>
          <a @click="toDepartment(record.hospital_id)">科室</a>
          <a-popconfirm title="确认删除该医院" ok-text="是" cancel-text="否" @confirm="del(record.hospital_id)">
            <a style="color: #e0352b">删除</a>
          </a-popconfirm>
        </a-space>
      </span>
    </a-table>
    <div>
      <a-modal destroyOnClose :title="(hospital_id ? '编辑' : '新增') + '医院'" :visible="visible" ok-text="提交" cancel-text="取消"
        @ok="submitForm" @cancel="handleCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="form" :rules="Rules">
          <a-form-model-item prop="name" label="医院名称：">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人：">
            <a-input v-model="form.contact"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="contact_phone" label="联系电话：">
            <a-input v-model="form.contact_phone"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="area_code" label="省市区">
            <a-cascader class="cascader" :default-value="addrCode"
              :field-names="{ label: 'name', value: 'adcode', children: 'districts' }" :load-data="loadSsq"
              change-on-select :options="ssqData" placeholder="请选择省市区" @change="ssqChange" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="详细地址：">
            <a-input v-model="form.address"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import { ssqdata } from '@/utils/ssqdata.js'
import axios from 'axios'

export default {
  data() {
    return {
      ssqData: [...ssqdata],
      query: {
        page: 1,
        limit: 10
      },
      pagetotal: 0,
      hospitals: [],
      hospital_id: null,
      visible: false,
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '医院名称',
          dataIndex: 'name'
        },
        {
          title: '联系人',
          dataIndex: 'contact'
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone'
        },
        {
          title: '详细地址',
          scopedSlots: {
            customRender: 'area'
          }
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      data: [],
      addressCode: [],
      form: {
        pro_code: '',
        pro: '',
        city_code: '',
        city: '',
        area_code: '',
        area: ''
      },
      addrCode: [],
      Rules: {
        name: [
          {
            required: true,
            message: '填写医院名称',
            trigger: 'blur'
          }
        ],
        contact: [
          {
            required: true,
            message: '填写联系人',
            trigger: 'blur'
          }
        ],
        contact_phone: [
          {
            required: true,
            message: '填写联系人电话',
            trigger: 'blur'
          }
        ],
        area_code: [
          {
            required: true,
            message: '填选择省市区',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '填写详细地址',
            trigger: 'blur'
          }
        ]
      },
      curParent: []
    }
  },
  created() {
    this.getList()
    this.getHospitals()
  },
  methods: {
    importHos() {
      this.$message.warn('不重要功能暂未开发')
    },
    Reset() {
      this.query = {
        page: 1,
        limit: this.query.limit
      }
      this.getList()
    },
    toEdit(item) {
      this.visible = true
      this.hospital_id = item.hospital_id
      this.form = {
        name: item.name,
        contact: item.contact,
        contact_phone: item.contact_phone,
        address: item.address,
        pro: item.pro,
        pro_code: item.pro_code,
        city: item.city,
        city_code: item.city_code,
        area: item.area,
        area_code: item.area_code
      }
      this.addrCode = [item.pro_code, item.city_code, item.area_code]
      this.ssqData = [...ssqdata]
      this.curParent = ssqdata

      this.initLoad(0)
    },
    add() {
      this.visible = true
    },
    handleCancel() {
      this.initForm()
    },
    initForm() {
      this.visible = false
      this.form = {}
      this.addrCode = []
      this.curParent = []
      this.ssqData = [...ssqdata]
      this.hospital_id = null
    },
    submitForm() {
      this.$refs.Form.validate(async valid => {
        if (valid) {
          // 先获取经纬度
          console.log('转化的地址')
          const addRes = await axios.get('https://restapi.amap.com/v3/geocode/geo', {
            params: {
              address: this.form.pro + this.form.city + this.form.area + this.form.address,
              city: this.form.city,
              key: 'ce7404ac71c28a7893cae3ccc5517da0'
            }
          })
          console.log(addRes)
          if (addRes.data.status == 1) {
            let location =
              addRes.data.geocodes[0] && addRes.data.geocodes[0].location
                ? addRes.data.geocodes[0].location.split(',')
                : ['', '']
            this.form.lng = location[0]
            this.form.lat = location[1]
          }

          this.confirmLoading = true
          let url = this.hospital_id ? 'hospitalEdit' : 'hospitalAdd'
          if (this.hospital_id) {
            this.form.hospital_id = this.hospital_id
          }
          this.$post('hospital/' + url, this.form)
            .then(res => {
              let { code, msg, data } = res
              this.confirmLoading = false
              if (code == 0) {
                this.$message.success(msg, 1.5)
                this.initForm()
                this.getList()
              } else {
                this.$message.error(msg, 1.5)
              }
            })
            .catch(err => {
              this.confirmLoading = false
            })
        }
      })
    },
    toDepartment(id) {
      console.log('thisthis', this.$router)
      this.$router.push({
        path: '/departmentList',
        query: {
          id: id
        }
      })
    },
    del(id) {
      this.$post(domain + '/admin/v1/hospital/hospitalDisable', { hospital_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getList() {
      this.$post(domain + '/admin/v1/hospital/hospitalList', this.query).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagetotal = res.data.total
          console.log('datadatadatadata', this.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getHospitals() {
      this.$post(domain + '/admin/v1/hospital/hospitalSelect').then(res => {
        if (res.code === 0) {
          this.hospitals = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.customer_id = null
      this.getList()
      console.log(query)
    },
    initLoad(ind) {
      console.log('dizhi code', this.addrCode)
      let code = this.addrCode[ind]
      for (let i = 0; i < this.curParent.length; i++) {
        let item = this.curParent[i]
        if (code == item.adcode) {
          const targetOption = item
          targetOption.loading = true
          let subdistrict =
            targetOption.level == 'province'
              ? 1
              : targetOption.level == 'city'
                ? 2
                : targetOption.level == 'district'
                  ? 3
                  : 4
          axios
            .get('https://restapi.amap.com/v3/config/district', {
              params: {
                keywords: targetOption.adcode,
                subdistrict: subdistrict,
                key: 'ce7404ac71c28a7893cae3ccc5517da0'
              }
            })
            .then(res => {
              for (let i = 0; i < res.data.districts[0].districts.length; i++) {
                let item = res.data.districts[0].districts[i]
                delete item.districts
                item.isLeaf = item.level == 'district'
              }
              console.log(res.data.districts[0].districts)
              targetOption.districts = res.data.districts[0].districts
              this.ssqData = [...this.ssqData]
              console.log('医院回显', this.ssqData)
              targetOption.loading = false
              this.curParent = res.data.districts[0].districts
              if (ind + 2 != this.addrCode.length) {
                this.initLoad(ind + 1)
              }
            })
            .catch(err => {
              console.log(err)
            })
          break
        }
      }
    },
    loadSsq(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      console.log(targetOption)
      targetOption.loading = true
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
            ? 2
            : targetOption.level == 'district'
              ? 3
              : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0'
          }
        })
        .then(res => {
          console.log(res)
          let districts = res.data.districts[0].districts
          console.log(res.data.districts[0].adcode == 810000)
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf = item.level == 'district'
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch(err => {
          console.log(err)
        })
    },
    ssqChange(e, opt) {
      this.form.pro_code = opt[0] ? opt[0].adcode : ''
      this.form.pro = opt[0] ? opt[0].name : ''
      this.form.city_code = opt[1] ? opt[1].adcode : ''
      this.form.city = opt[1] ? opt[1].name : ''
      this.form.area_code = opt[2] ? opt[2].adcode : ''
      this.form.area = opt[2] ? opt[2].name : ''

      console.log('省市区修改：', this.addrCode)
    }
  }
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
